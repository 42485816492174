<template>
  <div>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <form v-else @submit="onSubmit">
      <Step-Wizard :step="3"></Step-Wizard>

      <!-- Order details START -->
      <section id="orderDetails">
        <b-card>
          <Status-Badge
            class="float-right"
            :status="getCurrentOrder.statusName"
          ></Status-Badge>
          <h3><strong>Order:</strong> {{ getCurrentOrder.orderNo }}</h3>
          <hr />
          <b-form-group
            label-cols-lg="3"
            label="Details"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-group>
              <label><i class="fas fa-sitemap"></i> Project *</label>
              <b-input
                type="text"
                readonly
                required
                v-model="getCurrentOrder.projectName"
              >
              </b-input>
            </b-form-group>

            <b-form-group>
              <label><i class="fas fa-sitemap"></i> Project no *</label>
              <b-input
                type="text"
                readonly
                v-model="getCurrentOrder.projectNo"
                required
              >
              </b-input>
            </b-form-group>

            <b-form-group>
              <label><i class="fas fa-sticky-note"></i> Notes</label>
              <b-form-textarea
                v-model="getCurrentOrder.notes"
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>
          </b-form-group>
        </b-card>

        <b-form-group>
          <b-card>
            <b-form-group
              label-cols-lg="3"
              label="Contact"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >
              <b-form-group id="person">
                <label
                  ><i class="fas fa-address-book"></i> Contact person *</label
                >
                <b-input
                  type="text"
                  v-model="getCurrentOrder.orderedBy"
                  placeholder="Enter contact person"
                  required
                  readonly
                ></b-input>
              </b-form-group>
              <b-form-group id="mail">
                <label><i class="fas fa-envelope-open-text"></i> Email *</label>
                <b-input
                  type="text"
                  v-model="getCurrentOrder.mail"
                  required
                  placeholder="Enter e-mail"
                ></b-input>
              </b-form-group>
              <b-form-group id="phone">
                <label><i class="fas fa-phone"></i> Phone *</label>
                <b-input
                  type="text"
                  class="form-control"
                  v-model="getCurrentOrder.phone"
                  required
                  placeholder="Phone"
                ></b-input>
              </b-form-group>
            </b-form-group>
          </b-card>
        </b-form-group>

        <b-card>
          <b-form-group
            label-cols-lg="3"
            label="Delivery"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-container>
              <b-form-group>
                <label
                  ><i class="fas fa-calendar-alt"></i> Preferred delivery date
                </label>
                <b-form-input
                  id="preferredDeliveryDate"
                  type="date"
                  v-model="preferredDeliveryDate"
                ></b-form-input>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label
                      ><i class="fas fa-map-marker-alt"></i> Street name
                      *</label
                    >
                    <b-form-input
                      id="streetName"
                      type="text"
                      v-model="getCurrentOrder.deliveryAddress.streetName"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Zip code *</label>
                    <b-form-input
                      id="postalCode"
                      type="text"
                      v-model="getCurrentOrder.deliveryAddress.postalCode"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>City *</label>
                    <b-form-input
                      id="city"
                      type="text"
                      v-model="getCurrentOrder.deliveryAddress.city"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group>
                <label> Contact person on-site *</label>
                <b-form-input
                  id="contactPersonOnSite"
                  type="text"
                  v-model="getCurrentOrder.contanctPersonOnSite"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label> Contact person on-site phone *</label>
                <b-form-input
                  id="contactPersonOnSitePhone"
                  type="text"
                  v-model="getCurrentOrder.contactPersonOnSitePhone"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label> Comments</label>
                <b-form-input
                  id="contactPersonOnSiteComments"
                  type="text"
                  v-model="getCurrentOrder.contactPersonOnSiteComments"
                ></b-form-input>
              </b-form-group>
            </b-container>
          </b-form-group>
        </b-card>

        <div id="showItems" v-if="showItems">
          <Order-Lines-Card
            :order="getCurrentOrder"
            v-if="!showTable"
          ></Order-Lines-Card>
          <Order-Lines-Table
            :order="getCurrentOrder"
            v-else
          ></Order-Lines-Table>
        </div>
      </section>
      <!-- Order details END -->
      <section>
        <b-button variant="danger" @click="previous"> Previous</b-button>
        <b-button variant="success" type="submit" class="ml-2 finish"
          >Send</b-button
        >
      </section>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StepWizard from "@/components/StepWizard";
import OrderLinesTable from "@/components/OrderLinesTable";
import OrderLinesCard from "@/components/OrderLinesCard";
import StatusBadge from "@/components/StatusBadge";
import config from "@/store/config.js";
import moment from "moment";
import axios from "axios";

export default {
  name: "StepThree",
  components: {
    StepWizard,
    OrderLinesTable,
    OrderLinesCard,
    StatusBadge
  },
  data() {
    return {
      isLoading: true,
      error: null,
      showTable: true,
      showItems: false,
      orderId: null,
      orderNo: null
    };
  },
  mounted() {
    this.orderNo = this.$route.query.orderno || null;
    this.orderId = this.$route.query.id || null;

    if (this.orderId) {
      const self = this;
      this.loadOrder(this.orderId)
        .then(r => {
          if (r.data.statusNo !== "1") {
            //if status any other than 10 it's sendt and the admin must handle it form EDIT form.
            self.$router.push(`/order/view/${this.orderId}`);
          }
          this.isLoading = false;
          this.showItems = this.getCurrentOrder.orderLines.length > 0;
        })
        .catch(e => {
          self.error = "Failed when loading order: " + e;
        });
    } else {
      this.$router.push("/order/new/1");
    }

    this.isLoading = false;
  },
  methods: {
    ...mapActions(["updateCreateCurrentOrder", "loadOrder"]),
    previous() {
      this.$router.push({
        path: "/order/new/2",
        query: {
          id: this.orderId
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      this.isLoading = true;
      window.scrollTo(0, 0);

      let self = this;

      axios
        .get(`${config.API}/status/10`)
        .then(r => {
          this.getCurrentOrder.statusNo = r.data.statusNo;
          this.getCurrentOrder.statusName = r.data.name;

          this.$store
            .dispatch("updateCreateCurrentOrder", this.getCurrentOrder)
            .then(function() {
              self.projectId = null;
              self.$router.push(`/order/view/${self.orderId}`);
              self.error = null;
              this.isLoading = false;
            })
            .catch(e => {
              self.error = e;
              this.isLoading = false;
            });
        })
        .catch(e => {
          self.error = e;
        });
    }
  },
  computed: {
    ...mapGetters(["getCurrentOrder"]),
    preferredDeliveryDate: {
      get() {
        return moment(
          String(this.getCurrentOrder.preferredDeliveryDate)
        ).format("YYYY-MM-DD");
      },
      set(value) {
        this.getCurrentOrder.preferredDeliveryDate = value;
      }
    }
  }
};
</script>

<style></style>
